var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { padding: "30px" } }, [
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "center",
          "font-size": "24px",
          height: "60px",
          "margin-top": "30px",
          "line-height": "60px",
        },
      },
      [_vm._v(" 扬州大学报考点（3213）数据摸排实时统计表 ")]
    ),
    _c("div", [
      _c("div", { staticClass: "statics-wrapper" }, [
        _c("div", { staticClass: "statics-title" }, [_vm._v("数据总览")]),
        _c("div", { staticClass: "count-chart-container" }, [
          _c("div", { staticClass: "count-chart-item" }, [
            _c("span", { staticClass: "current-amount" }, [
              _vm._v(_vm._s(_vm.tableData.totalNum)),
            ]),
            _c("span", { staticClass: "sum" }, [_vm._v("总人数")]),
          ]),
          _c("div", { staticClass: "count-chart-item" }, [
            _c("span", { staticClass: "current-amount" }, [
              _vm._v(_vm._s(_vm.tableData.totalFill)),
            ]),
            _c("span", { staticClass: "sum" }, [_vm._v("总数据量")]),
          ]),
        ]),
      ]),
    ]),
    _c("div", [
      _c("div", { staticClass: "statics-wrapper" }, [
        _c(
          "div",
          { staticClass: "statics-search" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 16 } },
                  [
                    _vm._v("数据查询 "),
                    _c("el-date-picker", {
                      staticStyle: {
                        "margin-left": "20px",
                        "margin-right": "20px",
                      },
                      attrs: {
                        size: "mini",
                        type: "date",
                        placeholder: "选择日期",
                      },
                      model: {
                        value: _vm.search.statDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.search, "statDate", $$v)
                        },
                        expression: "search.statDate",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.onSearchClick },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: { "text-align": "right" },
                    attrs: { span: 8 },
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { type: "default", size: "mini" } },
                      [_vm._v("导出当前日期数据")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.showDebug
          ? _c(
              "div",
              [
                _c("div", [_vm._v("参数调试")]),
                _c("el-alert", [
                  _vm._v("主表单为导入的数据表,子表单为用户填写表"),
                ]),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _vm._v("主表单Id: "),
                    _vm.showDebug
                      ? _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: { size: "mini", placeholder: "masterFormId" },
                          model: {
                            value: _vm.search.masterFormId,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "masterFormId", $$v)
                            },
                            expression: "search.masterFormId",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _vm._v("子表单Id: "),
                    _vm.showDebug
                      ? _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: { size: "mini", placeholder: "slaveFormId" },
                          model: {
                            value: _vm.search.slaveFormId,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "slaveFormId", $$v)
                            },
                            expression: "search.slaveFormId",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _vm._v("主表单name字段: "),
                    _vm.showDebug
                      ? _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: { size: "mini", placeholder: "masterNameKey" },
                          model: {
                            value: _vm.search.masterNameKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "masterNameKey", $$v)
                            },
                            expression: "search.masterNameKey",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _vm._v("子表单name字段: "),
                    _vm.showDebug
                      ? _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: { size: "mini", placeholder: "slaveNameKey" },
                          model: {
                            value: _vm.search.slaveNameKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "slaveNameKey", $$v)
                            },
                            expression: "search.slaveNameKey",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _vm._v("主表单关联字段: "),
                    _vm.showDebug
                      ? _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            size: "mini",
                            placeholder: "masterRelationKey",
                          },
                          model: {
                            value: _vm.search.masterRelationKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "masterRelationKey", $$v)
                            },
                            expression: "search.masterRelationKey",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _vm._v("子表单关联字段: "),
                    _vm.showDebug
                      ? _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            size: "mini",
                            placeholder: "slaveRelationKey",
                          },
                          model: {
                            value: _vm.search.slaveRelationKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "slaveRelationKey", $$v)
                            },
                            expression: "search.slaveRelationKey",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "count-chart-container" }, [
          _c("div", { staticClass: "count-chart-item" }, [
            _c("span", { staticClass: "current-amount" }, [
              _vm._v(_vm._s(_vm.tableData.filledNum)),
            ]),
            _c("span", { staticClass: "sum" }, [_vm._v("已提交")]),
          ]),
          _c("div", { staticClass: "count-chart-item" }, [
            _c("span", { staticClass: "current-amount" }, [
              _vm._v(_vm._s(_vm.tableData.unFilledNum)),
            ]),
            _c("span", { staticClass: "sum" }, [_vm._v("未提交")]),
          ]),
          _c("div", { staticClass: "count-chart-item" }, [
            _c("span", { staticClass: "current-amount" }, [
              _vm._v(
                _vm._s(
                  (
                    (_vm.tableData.filledNum / _vm.tableData.totalNum) *
                    100
                  ).toFixed(0)
                ) + "%"
              ),
            ]),
            _c("span", { staticClass: "sum" }, [_vm._v("提交完成率")]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "count-chart-container",
            staticStyle: { "margin-top": "20px" },
          },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: this.tableData.data },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "relationKey", label: "考试号", width: "200" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "姓名", width: "200" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "fillStatus", label: "人员填报情况" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }