<template>
  <div style="padding:30px;">
    <div style="text-align:center;font-size:24px;height:60px;margin-top:30px;line-height:60px;">
      扬州大学报考点（3213）数据摸排实时统计表
    </div>
    <div>

      <div class="statics-wrapper">
        <div class="statics-title">数据总览</div>
        <div class="count-chart-container">
          <div class="count-chart-item"><span class="current-amount">{{tableData.totalNum}}</span><span class="sum">总人数</span></div>
          <div class="count-chart-item"><span class="current-amount">{{tableData.totalFill}}</span><span class="sum">总数据量</span></div>
        </div>
      </div>
    </div>
    <div>
      <div class="statics-wrapper">
        <!-- <div class="statics-title">数据查询</div> -->
        <div class="statics-search">
          <el-row>
            <el-col :span="16">数据查询
              <el-date-picker v-model="search.statDate" size="mini" type="date" placeholder="选择日期" style="margin-left:20px;margin-right:20px;">
              </el-date-picker>
              <el-button type="primary" size="mini" @click="onSearchClick">查询</el-button>
              
            </el-col>
            <el-col :span="8" style="text-align:right">
              <el-button type="default" size="mini">导出当前日期数据</el-button>
            </el-col>
          </el-row>
        </div>
        <div v-if="showDebug">
          <div>参数调试</div>
          <el-alert>主表单为导入的数据表,子表单为用户填写表</el-alert>
          <div style="margin-top:10px">主表单Id: <el-input v-model="search.masterFormId" size="mini" v-if="showDebug" style="width:150px" placeholder="masterFormId"></el-input></div>
          <div style="margin-top:10px">子表单Id: <el-input v-model="search.slaveFormId" size="mini"  v-if="showDebug" style="width:150px" placeholder="slaveFormId"></el-input></div>
          <div style="margin-top:10px">主表单name字段: <el-input v-model="search.masterNameKey" size="mini"  v-if="showDebug" style="width:150px" placeholder="masterNameKey"></el-input></div>
          <div style="margin-top:10px">子表单name字段: <el-input v-model="search.slaveNameKey" size="mini"  v-if="showDebug" style="width:150px" placeholder="slaveNameKey"></el-input></div>
          <div style="margin-top:10px">主表单关联字段: <el-input v-model="search.masterRelationKey" size="mini"  v-if="showDebug" style="width:150px" placeholder="masterRelationKey"></el-input></div>
          <div style="margin-top:10px">子表单关联字段: <el-input v-model="search.slaveRelationKey" size="mini"  v-if="showDebug" style="width:150px" placeholder="slaveRelationKey"></el-input></div>
              
        </div>
        <div class="count-chart-container">
          <div class="count-chart-item"><span class="current-amount">{{tableData.filledNum}}</span><span class="sum">已提交</span></div>
          <div class="count-chart-item"><span class="current-amount">{{tableData.unFilledNum}}</span><span class="sum">未提交</span></div>
          <div class="count-chart-item"><span class="current-amount">{{((tableData.filledNum / tableData.totalNum) * 100).toFixed(0)}}%</span><span class="sum">提交完成率</span></div>
        </div>
        <div class="count-chart-container" style="margin-top:20px;">
          <el-table :data="this.tableData.data" style="width: 100%">
            <el-table-column prop="relationKey" label="考试号" width="200">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="200">
            </el-table-column>
            <el-table-column prop="fillStatus" label="人员填报情况">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'
import moment from 'moment'
export default {
  data() {
    return {
      moment:moment,
      showDebug:false,
      search: {
        masterFormId: "1598864062807015424",
        masterRelationKey: "idcard69973",
        masterNameKey: "name51616",
        slaveFormId: "1598852950917120000",
        slaveRelationKey: "name92045",
        slaveNameKey: "",
        statDate: moment().format("YYYY-MM-DD")
      },
      tableData: {
        totalNum: '0',
        totalFill: '0',
        filledNum: '0',
        unFilledNum: '0',
        data: []
      }
    }
  },
  methods: {
    async onSearchClick() {
      if(event.ctrlKey){
        this.showDebug=!this.showDebug;
        return;
      }
      let res = await
        request({
          url: `/form/relation/master-slave`,
          method: 'post',
          data:this.search
        })
      this.tableData = res.data;
    }
  }
}
</script>
<style lang="scss" scoped>
.statics-wrapper {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  box-sizing: inherit;
  width: 100%;
  margin-bottom: 20px;

  .statics-title {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif;
    box-sizing: inherit;
    color: #1f2d3d;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 20px;
  }

  .statics-search {
    line-height: 50px;
    height: 50px;
  }

  .count-chart-container {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif;
    display: -webkit-flex;
    width: 100%;
    border: 1px solid #e5e5e5;
    padding: 20px 0;
    box-sizing: border-box;

    .count-chart-item {
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, Arial, sans-serif;
      box-sizing: inherit;
      text-align: center;
      font-weight: 400;
      display: -webkit-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      user-select: none;

      .current-amount {
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
          Microsoft YaHei, Arial, sans-serif;
        text-align: center;
        font-weight: 400;
        -webkit-box-direction: normal;
        user-select: none;
        box-sizing: inherit;
        font-size: 30px;
        color: #262a3e;
        margin-bottom: 10px;
      }
      .sum {
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
          Microsoft YaHei, Arial, sans-serif;
        text-align: center;
        font-weight: 400;
        -webkit-box-direction: normal;
        user-select: none;
        box-sizing: inherit;
        padding-left: 2px;
        color: #b0b0b9;
        font-size: 14px;
      }
    }
  }
}
</style>
